.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



a {
  text-decoration: none !important;

  
}


.murshidabadfinance{
  background-color: #dee0e4;
}


.container {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.colorb{
  background-color: #022466;
}

.apmcfinancial{

  background-color: #1b3ede; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 9px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 12px;
}

.fonts{
  font-size: 24px;
}

.textcolor h3{
  color: #0943b7;
}




.justify{
  text-align: justify;
  text-justify: inter-word;
}

.apmcloanberhampore{
  background-color: #041e8f; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 12px;
}

.murshidabadfinanceloan{
  background-color: #0c8299;
  padding: 30px;
}

.blink { 
  animation: blink-animation 1s infinite; 
} 
  
@keyframes blink-animation { 
  0% { 
    opacity: 1; 
  } 
  50% { 
    opacity: 0; 
  } 
  100% { 
    opacity: 1; 
  } 
}