.navbarbutton{
    background-color: #0174BD; /* Green */
    border: none;
    color: white;
    padding: 5px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 17px;
}

.bestfinanceberhampore{
background-color: #0174BD;
}

.applyloan{
    background-color: #f1c25b; /* Green */
    border: none;
    color: rgb(19, 18, 18);
    padding: 5px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    border-radius: 18px;
}



.btn-grad45 {
    background-image: linear-gradient(to right, #caa428 0%, #cd9f09  51%, #0a4c78  100%);
    margin: 1px;
    padding: 5px 17px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(255, 255, 255);            
    box-shadow: 0 0 10px #0c0351;
    border-radius: 20px;
    display: block;
  }
  
  .btn-grad45:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }