.marqueeback{
    background-color:rgb(165, 11, 63)
    
}


.colortext h4{
    color: #0174BD;
}

  


.containerloan {
    position: relative;
 
  }
  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .containerloan:hover .image {
    opacity: 0.3;
  }
  
  .containerloan:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #0174BD;
    color: white;
    font-size: 20px;
    padding: 20px 48px;
  }

  .headlink{
    background-color: #1489d1; /* Green */
    border: none;
    color: white;
    padding: 7px 66px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 11px;
  }